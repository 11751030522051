import "../../core/src/components/doc-properties/info-modal/info-modal.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/doc-properties/info-modal/info-modal.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA41S0WrbMBR9z1dcCoP0QUV2XLdTGewzBmMUxZJtgSwJ6cZJNvLvxYqc2upW9hYd5557zrnn4bUw3eMuPFH4swEY+IkclcCeQfVM3ellwpSZsV2dMMeFUKZjUFJ3AjpBXKvOkCB1yyAg9xhnue+UIWgdg6KMw5fNw7yziDuFCk7zM4NWy0ielhWUfpmeE0yE8rJBZQ2DxurDYFZEZSRqrUES1G/JYOR+SwhvW2UkiXhP6vuX+U9HqboeGdSUrnh2fxeUK/D2OMEddwzqLJD0nj3UuefqlnOfRDRcN9uC0rEHAuUU0v2KkUJZZSSPkSTtSBvRcxNa6wd2/ak5yh9bUkW6xWy9nI2Xm4XEh7NBXV3yfbD6gHI1/ZTkv5+1TJXYWy+kJ3uLaAcGueTn/4t2Pu5SiJeaoxrXQr5GvmXpItWteZ+WIQwx4sZq67PvY0lQnpAE2VgjuD/nx6iudufQdin+Va8qStcFuWx+Co6cOG+d9HgmjdWauyDFtzv0B3n3CzJnt6SMNbnzMHZ54Ysk4x+WVGPNytKSj/V2lP7G+hmF82qYCb4PUigOziuDcTBz8MEDwGVzeQMM0I0FcQQAAA==\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var addPropertyButton = '_1ng53s79';
export var container = '_1ng53s70';
export var hiddenInput = '_1ng53s76';
export var rowNameContainer = '_1ng53s73';
export var scrollBar = '_1ng53s75';
export var tableBodyRoot = '_1ng53s78';
export var timeRow = '_1ng53s77';
export var titleContainer = '_1ng53s71';
export var titleStyle = '_1ng53s72';
export var viewport = '_1ng53s74';