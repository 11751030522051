import "../../core/src/components/doc-properties/table.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/doc-properties/table.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA6VW7W7bIBT936dAlSYl0sgcN81aukrTXmAPMPUHMdcOGwYE2Ik39d0n8Edx7GzJ9isx3I9zLudeWDUPOn0QKfp1gxDG7VdC0Hqb6ONTtLYmqKZmgTHNcy4B50o6bMult2HcakEbgnIBwenAmdsTtE6Sd/7ze2UdzxucKelAOoIykA6M3wphclpy0cwmoNJ228unm9ebVQvmLsCdpPW/mHEDmeNKEpQpUZXS7xRUE/Sg58CV9Ii7pVF+YNwp024FlpoyxmVBUDJraDkD3Nm8R+lGH2PEm2sRT2pmNc0A78AdAGQU+f6yyEYdLgmLEBW8kJg7KG18UJkSypyUqE6xg6PDFjIlGTXNcjhSy39Cb90J6G3zALzYO4LukyScAJd43y3dbfwhDeS2V5G7AnrArQ0vqfHFEMr8G/ZIFNtWXrHUelbpmNXH86wIWs8eU2AMkgU+lbGekFa8pTgEfgiBLz2qwe0xuE2pTBIhtFOGgcGGMl5Zgsa8Hsle1WBCtB3NfhRGVZLhM3gEbcDgyC44f63BCDqCR/+n2ycnOplfQ57dtXlGerOOGneava8gg5xWws0pZkifhfS9ZJKo2k5pgpLVvT4iqwRnZ0rJpZ9BX1qf1nU5O/BMwWWA6LMM+dm/l3mIAdfG0MryrodBUMdriKLlV3V/V9i2WmkL6xujjmJtlAbjQqMLqi2w51tnKrh9QfOZpJIxjiLs/l1J10zJabeNR+DJuNm04yaUfDu+x/qbeq415wZ+q9OInK0nBNf9BJjnwzMl50dJ0Y2APuafAnTjN3bfByelacZdQ0byzLvI85bryJKHLWeo7LUV/ufKlChZpRYBtYC5xKqKC8FbsUw1MkTzEbzcHHWweEwYFDH27ydz79zEO+y5g+XJ0yOjIlucf3+gDyid7+Xj29WZ1Hu/5quUC3XADUG0ciqC+IPsqV2cNEVNRQUv7SKU2jU98eV8U3z2UqJcgkE1h4NWxqFFwIU+PaPtJtHH1nMVvy3/8pI82kDv9c3trnOLGqVX5atHUQLjFGnDpYuz3XduJ7BHobcX2GQX2LC2aNbr4flWaZCdWvohi3fKOVUSdJf2yAfn4nyC198Bc47glgsAAA==\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var actionContainer = 'y8p28lf';
export var backLinksMenu = 'y8p28lj';
export var backlinksList = 'y8p28la';
export var collapsedIcon = 'y8p28li';
export var fontSize = 'var(--y8p28l1)';
export var pageInfoDimmed = 'y8p28l8';
export var propertyActionButton = 'y8p28lg';
export var propertyConfigButton = 'y8p28lh';
export var propertyRootHideEmpty = 'y8p28lk';
export var root = 'y8p28l2';
export var rootCentered = 'y8p28l3';
export var tableBodyRoot = 'y8p28ld';
export var tableBodySortable = 'y8p28le';
export var tableHeader = 'y8p28l4';
export var tableHeaderBacklinksHint = 'y8p28l9';
export var tableHeaderCollapseButtonWrapper = 'y8p28l7';
export var tableHeaderDivider = 'y8p28lc';
export var tableHeaderInfoRow = 'y8p28l5';
export var tableHeaderSecondaryRow = 'y8p28l6';
export var tableHeaderTimestamp = 'y8p28lb';