import "../../core/src/components/doc-properties/tags-inline-editor.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/doc-properties/tags-inline-editor.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA51V247TMBB971cMD0hbVl4l3bYsruA7kNA+uPYkMTi25UzaFMS/ozjXbltEeWiqjMdnzpy55CnL/ctqlXxTggTD0tPpM4UaX+HXAkA64wKHgwgPjIks0xaZN0Ji4YzCwOL5crf4vXjqcNJ4TenKG3HikBlsdguI/0zpgJK0s7zFrUvbnhy1ooJDmiTv29dceA7pyjczzNV1zOi69RP+MbSW9nkBPGI9RywvlNI255C0nt5VuqMV0AjSB2yt5DwHKYx8YM9PG9/AYy9DEEo3zDvvMTBhZeECK1DnBS3hA7B02d42mBGH7uaMTcS7DRO9lvAI6dY3EccdMGTGHTkUWim0s1TWf5FlfVOWMfU08U2vNMBeyB95cLVV7FrJi5bGWGyAUts+ZQ7r82JtIqsYOA8t7/QyalS9FCHXlgObTHsXFAYO1tlYAleT0RYnQ+YssUr/RA7pkGJrykSpzYmDtgUGTbupc2eWyxwpCFt5EdDSPAPOZy3+f2OwvXcMYtVeupSm7mwtw6/TrJl0T5LOOLWIqMnNSHy8TuJ7XZHOTkw6S2iJQ+WFRLZHOiJGMsLo3DJNWFYcJFrCcMnrTT3OtIn2plqOPsee9CZJdjf0JGyIVSidVSKcLiV9idkMybJmmohbEzxXazWoNQk9In/6t2IFd7xLnCH08/ptZABZh6pVwDs9AHTNH9dCXfUTPFHs1nPmZF2hmi3ouwZ3hBOdll5ITae4BadIPN6B657pHKTjVJEg/Ow82tfbvvsrW2E8lPGw35Cr5Fy94f2iPDfqcNHdw9EYTs3DpdvzcMP7m2pszj4iWUSI2yNzoewXiRGEXx/WcXGPrvm9i+Bq094c8byrQfctqWRwxjARULCDxqN3gV7hCyh9OGdBYm8Q3umy9RBx+f0BWzkzfAcIAAA=\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var inlineTagsContainer = 'fgp8222';
export var menuItemList = 'fgp822g';
export var menuItemListScrollable = 'fgp822e';
export var menuItemListScrollbar = 'fgp822f';
export var searchInput = 'fgp8225';
export var spacer = 'fgp822b';
export var tagColorIcon = 'fgp822d';
export var tagColorIconWrapper = 'fgp822c';
export var tagEditIcon = 'fgp822a';
export var tagSelectorItem = 'fgp8229';
export var tagSelectorTagsScrollContainer = 'fgp8228';
export var tagsEditorRoot = 'fgp8221';
export var tagsEditorSelectedTags = 'fgp8224';
export var tagsEditorTagsSelector = 'fgp8226';
export var tagsEditorTagsSelectorHeader = 'fgp8227';
export var tagsInlineEditor = 'fgp8220';
export var tagsMenu = 'fgp8223';